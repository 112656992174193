import { pick } from 'lodash-es'
import { FIELD_TYPE_NAME } from '#field/constant'
import type { Field, FieldSyncPayload } from '#field/types'
import { getIdentifier, createFieldModifiers } from '#core/utils/apollo'
import type { FieldsModifier } from '#core/types'

export const useFieldDataConvert = () => {
  const getFieldIdentifier = (id: string) => getIdentifier(id, FIELD_TYPE_NAME)
  const fields = ['name', 'description', 'default', 'options', 'type']

  // For app.add
  const getOptimisticField = (id: string | null, payload: Partial<Field>, forFields = false): Partial<Field> & { __typename?: string } => {
    const defaults = { options: null, default: null }
    const field = getOptimisticObject(id, payload, fields, defaults, forFields)

    return forFields ? field : { ...field, __typename: FIELD_TYPE_NAME }
  }

  // For app.update
  const getOptimisticFieldFields = (payload: Partial<Field>) => {
    const field = getOptimisticField(null, payload, true)
    return convertObjectToFields(field)
  }

  // For socket.add use raw data from socket messages, no conversion needed

  // For socket.update
  const getSyncFieldFields = (payload: FieldSyncPayload) => {
    const fieldModifiers = createFieldModifiers(fields, payload)
    return pick(fieldModifiers, Object.keys(payload)) as FieldsModifier<Field>
  }

  return {
    getFieldIdentifier,
    getOptimisticField,
    getOptimisticFieldFields,
    getSyncFieldFields,
  }
}
